
import { executeFrameRequest } from '@/unlayer-tools/requests/frame-request';

export function requestPageList() {
    return executeFrameRequest('getUnlayerPages', {});
}

export function requestFocusPage(pageId) {
    return executeFrameRequest('focusUnlayerPage', { pageId });
}

export function requestDeletePage(pageId) {
    return executeFrameRequest('deleteUnlayerPage', { pageId });
}

export function requestCreatePage(newPage = {}) {
    return executeFrameRequest('createUnlayerPage', { newPage });
}

export function requestCopyPage(existingPageId) {
    return executeFrameRequest('copyUnlayerPage', { existingPageId });
}

export function requestSavePage(pageId, pageData) {
    return executeFrameRequest('saveUnlayerPage', { pageId, pageData });
}

export function requestUnlayerPageDetails(pageId) {
    return executeFrameRequest('getUnlayerPageDetails', { pageId });
}

export function requestSortPages(pageIds) {
    return executeFrameRequest('sortUnlayerPages', { pageIds });
}

export function requestCurrentPage() {
    return executeFrameRequest('getCurrentPage', {});
}

export function requestCreateCheckoutForm(pageId, values) {
    return executeFrameRequest('createCheckoutFormRequest', { pageId, values });
}

export function requestUpdateSiteSettings(updatedValues) {
    return executeFrameRequest('updateSiteSettings', { ...updatedValues });
}

export function requestRenameSite(name) {
    return executeFrameRequest('renameSite', { name });
}

export function requestUpdateSlug(slug) {
    return executeFrameRequest('updateSiteSlug', { slug });
}

export function requestHandleFaviconUpload(attachments) {
    return executeFrameRequest('uploadFavicon', { attachments });
}

export function requestSiteSettings() {
    return executeFrameRequest('getSiteSettings', {});
}

export function requestPopupList() {
    return executeFrameRequest('getPopupList', {});
}

export function requestPopupSave(selectedPopup ) {
    return executeFrameRequest('savePopup', selectedPopup );
}

export function requestSelectedPopup() {
    return executeFrameRequest('fetchPopupDependency', {});
}

export function requestPopupDelete(popupId) {
    return executeFrameRequest('deletePopup', { popupId });
}
