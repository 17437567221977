import React from 'react';
import { DragSource, DropTarget, DndProvider } from 'react-dnd';
import { findDOMNode } from 'react-dom';
import DragIcon from '@/icons/drag.svg';
import EditIcon from '@/icons/edit.svg';
import DeleteIcon from '@/icons/delete.svg';
import CopyIcon from '@/icons/copy.svg';
import classNames from 'classnames';
import style from './CardTile.scss';
import { Icon } from '@/shared/components/Icon';
import { HTML5Backend } from 'react-dnd-html5-backend';

class CardTile extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const { pageName } = this.props;
        const { onSelect, onEdit, isFocused, onDelete, onCopy, isCopyPageEnabled, canCopy } = this.props;
        const { isSaving, isDragging, connectDragSource, connectDragPreview, connectDropTarget, canDelete } = this.props;

        const cardClasses = classNames({
            [style.focused]: isFocused,
            [style.dragging]: isDragging,
            [style.saving]: isSaving,
            [style.CardTile]: true,
        });

        return connectDropTarget(
            <div className={style.cardTileContainer} data-testid={this.props['data-testid'] || 'card-tile'}>
                <div className={cardClasses} onClick={onSelect}>
                    {connectDragPreview(
                        <div>
                            {connectDragSource(
                                <div>
                                    <div style={{
                                        opacity: isDragging ? 0 : 1,
                                        cursor: 'default',
                                        padding: '13px',
                                    }}
                                    role="button">
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>
                                            <div style={{ marginRight: '16px', cursor: 'pointer' }}>
                                                <i>
                                                    <Icon height={17} width={8} icon={DragIcon} />
                                                </i>
                                            </div>

                                            <div data-testid="card-title" className={style.label} style={{ flex: 1, marginRight: '16px' }}>{pageName}</div>
                                            {
                                                !isSaving &&
                                                <div  style={{ cursor: 'pointer', marginLeft: '11px' }}>
                                                    <svg data-testid="edit-page" height={22} width={21} viewBox={EditIcon.viewBox}
                                                        onClick={onEdit}>
                                                        <use xlinkHref={`#${EditIcon.id}`} />
                                                    </svg>
                                                </div>
                                            }
                                            {
                                                isCopyPageEnabled && canCopy && <div style={{ cursor: 'pointer', marginLeft: '11px' }}>
                                                    <svg data-testid="copy-page" height={22} width={21} viewBox={CopyIcon.viewBox}
                                                        onClick={onCopy}>
                                                        <use xlinkHref={`#${CopyIcon.id}`} />
                                                    </svg>
                                                </div>
                                            }
                                            {
                                                canDelete && <div style={{ cursor: 'pointer', marginLeft: '11px' }}>
                                                    <svg data-testid="delete-page" height={22} width={21} viewBox={DeleteIcon.viewBox} onClick={onDelete} >
                                                        <use xlinkHref={`#${DeleteIcon.id}`} />
                                                    </svg>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>,
                            )}
                        </div>,
                    )}
                </div>
            </div>,
        );
    }
}

const pageSource = {
    beginDrag(props) {
        return {
            id: props.id,
            index: props.index,
        };
    },
};

const pageTarget = {
    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        // eslint-disable-next-line react/no-find-dom-node
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        props.movePage(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

// eslint-disable-next-line no-class-assign
CardTile = DragSource(
    'FIELD',
    pageSource,
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    }),
)(CardTile);

// eslint-disable-next-line no-class-assign
CardTile = DropTarget(
    'FIELD',
    pageTarget,
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
    }),
)(CardTile);

// eslint-disable-next-line react/display-name
export default (props) => (
    <DndProvider backend={HTML5Backend} context={global}>
        <CardTile {...props} />
    </DndProvider>

);
