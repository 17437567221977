import { intl } from '@/shared/intl';

export const progressToolOptions = () => {
    const progressBarConstants = {
        progressBar: {
            position : 1,
            title: 'Progress Bar',
            options: {
                progressBarTextLabel: {
                    label: intl.get('tool.progress.bar.title.label', { defaultMessage: 'Progress Bar Title' }),
                    defaultValue: 'Progress Bar',
                    widget: 'text',
                },
                progressBarValue: {
                    label: intl.get('tool.progress.bar.value.label', { defaultMessage: 'Progress Value' }),
                    defaultValue: {
                        percentageValue: 25,
                        fontSizeValue: 14,
                    },
                    widget: 'keap#progress#editor',
                },
                progressBarTextAlign : {
                    label: intl.get('tool.progress.bar.alignment.label', { defaultMessage: 'Text Alignment' }),
                    defaultValue : 'left',
                    widget: 'alignment',
                },
                progressBarColor : {
                    label: intl.get('tool.progress.bar.color.label', { defaultMessage: 'Progress Bar Color' }),
                    defaultValue: '#000000',
                    widget: 'color_picker',
                },
                progressBarWidth: {
                    label: intl.get('tool.progress.bar.width.label', { defaultMessage: 'Width' }),
                    defaultValue: {
                        autoWidth: true,
                        width: '100%',
                    },
                    widget: 'auto_width',
                },
                progressBarTextColor: {
                    label: intl.get('tool.progress.bar.text.color.label', { defaultMessage: 'Text Color' }),
                    defaultValue: '#000000',
                    widget: 'color_picker',
                },
                percentageColor: {
                    label: intl.get('tool.percentage.color.label', { defaultMessage: 'Percentage Color' }),
                    defaultValue: '#FFFFFF',
                    widget: 'color_picker',
                },

            },
        },
    };

    return progressBarConstants;
};
