import { executeFrameRequest } from '@/unlayer-tools/requests/frame-request';

const onCreate = async ({ done, inputValue, options, origin }) => {
    try {
        const newTag = await executeFrameRequest('keapCreateTagRequest', { inputValue }, origin, 'parent');

        setEmailLinkTypesWithOptions({
            ...options,
            tags: [...options.tags, newTag],
        });

        done(newTag);
    } catch (error) {
        done();
    }
};

export function setEmailLinkTypes (i18n, tags) {
    setEmailLinkTypesWithOptions({ i18n, tags });
}

export function setEmailLinkTypesWithOptions (options) {
    const {
        i18n,
        tags,
        isCampaignLinksEnabled,
        campaignLinks,
        isMultipleTagsEnabled,
        isTagCreationEnabled,
        isFileDownloadLinksEnabled,
        origin,
        tagLimit,
        files,
    } = options;

    window.unlayer.setLinkTypes([
        {
            name: 'sms',
            enabled: false,
        },
        isFileDownloadLinksEnabled ? {
            name: 'file_download',
            label: i18n.fileDownloadLabel,
            attrs: {
                href: '{{file}}',
                download: true,
                'data-tag-id': '{{tag}}',
            },
            fields: [
                {
                    name: 'file',
                    label: i18n.fileLabel,
                    defaultValue: undefined,
                    isMulti: false,
                    options: [
                        {
                            label: i18n.openFileManagement,
                            value: '',
                            async onClick(_option, _meta, done) {
                                const { url, name, files } = await executeFrameRequest('keapFileUploadRequest', null, '*', 'parent');

                                setEmailLinkTypesWithOptions({ ...options, files });

                                if (url && name) done({ value: url, label: name });
                            },
                            highlight: true,
                        },
                        ...files,
                    ],
                },
                {
                    name: 'tag',
                    label: i18n.applyTagLabel,
                    defaultValue: isMultipleTagsEnabled ? [] : '',
                    inputType: null,
                    placeholderText: null,
                    isMulti: isMultipleTagsEnabled || false,
                    isCreatable: isTagCreationEnabled || false,
                    limit: tagLimit,
                    limitMessage: i18n.tagsLimitReachedLabel,
                    options: tags,
                    onCreateOption: (inputValue, _, done) => onCreate({ done, inputValue, options, origin }),
                },
            ],
        } : {},
        {
            name: 'web',
            label: i18n.openWebsiteLabel,
            attrs: {
                href: '{{href}}',
                target: '_blank',
                'data-tag-id': '{{tag}}',
            },
            fields: [
                {
                    name: 'href',
                    label: i18n.urlLabel,
                    defaultValue: '',
                    inputType: 'url',
                    placeholderText: null,
                },
                {
                    name: 'tag',
                    label: i18n.applyTagLabel,
                    defaultValue: isMultipleTagsEnabled ? [] : '',
                    inputType: null,
                    placeholderText: null,
                    isMulti: isMultipleTagsEnabled || false,
                    isCreatable: isTagCreationEnabled || false,
                    limit: tagLimit,
                    limitMessage: i18n.tagsLimitReachedLabel,
                    options: tags,
                    onCreateOption: (inputValue, _, done) => onCreate({ done, inputValue, options, origin }),
                },
            ],
        },
        {
            name: 'campaign_link_type',
            label: i18n.campaignLinkDropdownLabel,
            enabled: isCampaignLinksEnabled || false,
            defaultValue: '',
            attrs: {
                href: '{{campaignlink}}',
                target: '_blank',
                'data-campaign-link-id': '{{campaignlink}}',
                'data-tag-id': '{{tag}}',
            },
            fields: [
                {
                    name: 'campaignlink',
                    label: i18n.campaignLinkLabel,
                    defaultValue: '',
                    inputType: null,
                    placeholderText: null,
                    options: campaignLinks,
                },
                {
                    name: 'tag',
                    label: i18n.applyTagLabel,
                    defaultValue: isMultipleTagsEnabled ? [] : '',
                    inputType: null,
                    placeholderText: null,
                    isMulti: isMultipleTagsEnabled || false,
                    isCreatable: isTagCreationEnabled || false,
                    limit: tagLimit,
                    limitMessage: i18n.tagsLimitReachedLabel,
                    options: tags,
                    onCreateOption: (inputValue, _, done) => onCreate({ done, inputValue, options, origin }),
                },
            ],
        },
    ]);
}
