import React, { useState } from 'react';
import style from './progressBarTool.scss';
import { intl } from '@/shared/intl';

export const KeapProgressBarPropertyEditor = ({ values, updateValue }) => {
    const { progressBarValue } = values;
    const { percentageValue, fontSizeValue } = progressBarValue;

    const [percentage, setPercentage] = useState(percentageValue);
    const [error, setError] = useState('');
    const [fontSize, setFontSize] = useState(fontSizeValue);

    const handlePercentageChange = (e) => {
        const newValue = Number(e.target.value);

        if (newValue > 100) {
            setError('Maximum value is 100 only');
        } else {
            updateValue({
                percentageValue: newValue,
                fontSizeValue: fontSize,
            });
            setPercentage(newValue);
            setError('');
        }
    };

    const handleFontSizeChange = (e) =>  {
        const newFontSize = Number(e.target.value);

        setFontSize(newFontSize);
        updateValue({
            fontSizeValue: newFontSize,
            percentageValue: percentage,
        });
    };

    return (
        <>
            <div className={style.containerStyle}>
                <p className={style.labelStyle}>{intl.get('tool.progress.bar.value.label', { defaultMessage: 'Progress Value' })}</p>
                <div>
                    <input
                        className={style.inputStyle}
                        type="Number"
                        value={percentage}
                        min="0"
                        max="100"
                        onChange={handlePercentageChange}
                    />%
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>

            <div className={style.fontContainerStyle}>
                <p className={style.labelStyle}>{intl.get('tool.progress.bar.font.size.label', { defaultMessage: 'Font Size' })}</p>
                <div>
                    <input
                        className={style.inputStyle}
                        type="Number"
                        value={fontSize}
                        min="0"
                        max="48"
                        onChange={handleFontSizeChange}
                    />px
                </div>
            </div>
        </>
    );
};
