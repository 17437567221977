import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { intl } from '@/shared/intl';
import style from '../../editors/styles/FormEditorStyle.scss';

const WarningAlertMessage = ({ alertMessage }) => {
    const [isVisible, setIsVisible] = useState(true);

    return (
        <>
            {isVisible && (
                <div className={style.virtualFieldAlert} color="secondary">
                    <div className={style.alertContainer}>
                        <div className={style.alertIcon}>
                            <FontAwesomeIcon icon={faExclamationCircle} />
                        </div>
                        <div className={style.labelStyle}>
                            {alertMessage}
                        </div>
                        <p
                            className={style.alertClose}
                            onClick={() => setIsVisible(false)}
                        >
                            {intl.get('unlayer.tool.form.virtual.field.alert.close', {
                                defaultMessage: 'x',
                            })}
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default WarningAlertMessage;
