// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wl5DyXOB1bGBYDVnKBpF{font-size:12px;font-weight:500;line-height:24px;color:#515151;font-family:inherit}.QfLJFSCqy_6W1oDBQDX3{display:flex;justify-content:space-between;align-items:baseline}.zp3jxGjQxQV3lqfURAqH{display:flex;justify-content:space-between;align-items:baseline;margin-top:24px;padding-top:24px;border-top:1px solid #eee}.nkJjt4MfqSzSNblzHr1G{padding:6px 6px;border:1px solid #ced4da;border-radius:4px}\n", "",{"version":3,"sources":["webpack://./src/unlayer-tools/custom-tools/progress-bar-tool/progressBarTool.scss"],"names":[],"mappings":"AAAA,sBACE,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,aAAsB,CACtB,mBAAoB,CACnB,sBAGC,YAAa,CACb,6BAA8B,CAC9B,oBAAqB,CACtB,sBAGC,YAAa,CACb,6BAA8B,CAC9B,oBAAqB,CACrB,eAAgB,CAChB,gBAAiB,CACjB,yBAAwC,CACzC,sBAGC,eAAgB,CAChB,wBAAoC,CACpC,iBAAkB","sourcesContent":[".labelStyle {\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 24px;\n  color: rgb(81, 81, 81);\n  font-family: inherit;\n  }\n\n.containerStyle {\n    display: flex;\n    justify-content: space-between;\n    align-items: baseline;\n  }\n\n.fontContainerStyle {\n    display: flex;\n    justify-content: space-between;\n    align-items: baseline;\n    margin-top: 24px;\n    padding-top: 24px;\n    border-top: 1px solid rgb(238, 238, 238);\n  }\n\n.inputStyle {\n    padding: 6px 6px;\n    border: 1px solid rgb(206, 212, 218);\n    border-radius: 4px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelStyle": "Wl5DyXOB1bGBYDVnKBpF",
	"containerStyle": "QfLJFSCqy_6W1oDBQDX3",
	"fontContainerStyle": "zp3jxGjQxQV3lqfURAqH",
	"inputStyle": "nkJjt4MfqSzSNblzHr1G"
};
export default ___CSS_LOADER_EXPORT___;
