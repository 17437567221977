import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { progressToolOptions } from './progress-tool.constant';
import { KeapProgressBarPropertyEditor } from './KeapProgressBarPropertyEditor';
import { progressBarCss } from './progressBarCss';
import { progressToolProcessing } from './progressToolProcessing';
import ProgressIcon from '@/icons/progress.svg';
import { intl } from '@/shared/intl';

export const KeapProgressBarViewer = ({ values }) => {
    const {
        progressBarWidth,
        progressBarTextColor,
        progressBarTextAlign,
        progressBarTextLabel,
        progressBarValue,
        percentageColor,
    } = values;
    const { width } = progressBarWidth;
    const [displayedPercentage, setDisplayedPercentage] = useState(0);
    const { percentageValue, fontSizeValue } = progressBarValue;

    useEffect(() => {
        const start = displayedPercentage;
        const end = percentageValue;
        const duration = 1500;
        const frameRate = 50;
        const increment = (end - start) / (duration / frameRate);

        let progress = start;
        let timeoutId;

        const step = () => {
            progress += increment;

            if ((increment > 0 && progress >= end) || (increment < 0 && progress <= end)) {
                progress = end;
                setDisplayedPercentage(Math.round(progress));

                return;
            }

            setDisplayedPercentage(Math.round(progress));
            timeoutId = setTimeout(step, frameRate);
        };

        step();

        return () => clearTimeout(timeoutId);
    }, [percentageValue]);

    return (
        <div>
            <div style={{ color: progressBarTextColor, textAlign: progressBarTextAlign }}>
                <p style={{ fontSize: fontSizeValue }}>
                    {progressBarTextLabel}
                </p>
            </div>
            <progress
                id="keap-progress"
                style={{ width: width, height: '2rem', position: 'relative' }}
                max={100}
                value={displayedPercentage}
            ></progress>
            <p className='progress-percentage' style={{ position: 'absolute', color:percentageColor, top:'50%', left:'49%' }}>{`${displayedPercentage}%`}</p>
        </div>
    );
};

export const KeapProgressTool = () => ({
    name: 'keap#progress_bar_tool',
    label: intl.get('tool.progress.bar.label', { defaultMessage: 'Progress' }),
    icon: `<svg height="33" width="33" viewBox="${ProgressIcon.viewBox}">
        <use xlink:href="#${ProgressIcon.id}" />
    </svg>`,
    supportedDisplayModes: ['web', 'email'],
    options: { ...progressToolOptions() },
    renderer: {
        Viewer: KeapProgressBarViewer,
        exporters: {
            web(values) {
                const props = { values };

                return ReactDOMServer.renderToStaticMarkup(<KeapProgressBarViewer {...props} />);
            },
        },
        head: {
            css({ progressBarColor }) {
                return progressBarCss(progressBarColor);
            },
            js({ progressBarValue }) {
                const { percentageValue } = progressBarValue;

                return progressToolProcessing(percentageValue);
            },
        },
    },
});

export const KeapProgressEditor = {
    name: 'keap#progress#editor',
    Widget: KeapProgressBarPropertyEditor,
};
