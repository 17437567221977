export const progressToolProcessing = (percentageValue) => {
    return `
document.addEventListener('DOMContentLoaded', function () {
    const progressBar = document.getElementById('keap-progress');
    const progressPercentage = document.querySelector('.progress-percentage');
    if (progressBar && progressPercentage) {
        let start = 0;
        const end = parseFloat(${percentageValue}) || 0;
        const duration = 2000;
        const stepInterval = 50;
        const increment = (end - start) / (duration / stepInterval);

        let progress = start;

        const step = () => {
            progress += increment;
            const currentProgress = Math.min(Math.max(progress, 0), 100);
            progressBar.value = currentProgress;
            progressPercentage.textContent = Math.round(currentProgress) + ' %';

            if ((increment > 0 && progress < end) || (increment < 0 && progress > end)) {
                setTimeout(step, stepInterval);
            }
        };

        step();
    }
});
`;
};
