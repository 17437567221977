export const progressBarCss = (progressBarColor) => {
    return (` progress::-webkit-progress-value {
          background-color: ${progressBarColor};
          border-radius: 2rem;
      }
      progress::-webkit-progress-bar {
          background-color: gray;
          border-radius: 2rem;
      }
      progress::-moz-progress-bar {
        background-color: ${progressBarColor} ;
      }
      progress {
      background-color: gray;
      border-radius: 2rem;
      }
  `);
};
